import * as React from "react";
import styles from "./MapBox.module.scss";
import { ReactComponent as School } from "../../assests/markers/school.svg";
import { MARKER_ICON_MAPPING } from "../../constant/CategoryData";

const test = (title: string) => {
  const icon = MARKER_ICON_MAPPING.find((x) => x.name === title)?.Icon || "";
  return icon;
};

function ControlPanel({ wardId, setIcons, icons }: any) {
  const handleClick = (e: any) => {
    if (icons.some((x: any) => x === e.currentTarget.value)) {
      setIcons(icons.filter((x: any) => x !== e.currentTarget.value));
    } else {
      setIcons([...icons, e.currentTarget.value]);
    }
  };
  console.log(icons);
  return (
    <div className={styles["control-panel"]}>
      <h4>Legend</h4>
      <div className={styles["icon-div"]}>
        {MARKER_ICON_MAPPING.map((x: any) => (
          <div className={styles["icon-align"]} key={x.Icon}>
             <input
              type="checkbox"
              checked={icons.some((y: any) => y === x.name)}
              name={x.name}
              id={x.name}
              value={x.name}
              style={{ marginRight: "15px" }}
              onClick={handleClick}
            />
            <img src={x.Icon} />
            {x.name_np}
           
          </div>
        ))}
      </div>
    </div>
  );
}

export default React.memo(ControlPanel);
