import React from "react";
import logo from "./logo.svg";
import "./App.css";
import LoginMain from "./components/LoginMain";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import RouteWrapper from "./components/Route/RouteWrapper";
import "bootstrap/dist/css/bootstrap.min.css";
import Contact from "./components/Contact/Contact";
import "./App.scss";
import Maps from "./components/Maps";
function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<RouteWrapper component={Home} />} />
          <Route
            path="/contact"
            element={<RouteWrapper component={Contact} />}
          />
          <Route path="/maps" element={<RouteWrapper component={Maps} />} />
          <Route
            path="/support"
            element={<RouteWrapper component={Contact} />}
          />
          <Route path="/login" element={<LoginMain />} />
          {/* <Route
            path="/test"
            element={
              <PrivateRoute isAuthenticated={false} outlet={<>Hello World</>} />
            }
          > */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
