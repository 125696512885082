import { useCallback, useState } from "react";
import { apiService } from "../Services/HttpServices";

const URL = "https://api.venture4tech.com/api/DemographicLocation/populations";
//const URL = "https://localhost:5001/api/DemographicLocation/populations";
export const useGetPopulation = (wardId: number | undefined) => {
  const [population, setpopulation] = useState<any>();
  const [error, setError] = useState<string>();
  const [isloading, setLoading] = useState<boolean>(true);
  const fetchPopulation = useCallback(async () => {
    try {
     // setpopulation(null);
      setLoading(true);
      const { data } = await apiService.get<any>(URL + `?ward=${wardId || ""}`);
      setpopulation(data);
      setLoading(false);
    } catch (error: any) {
      setError(error);
    }
  }, [URL, wardId]);

  return { fetchPopulation, population, error, isloading };
};
