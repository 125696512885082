import { MapImage, MAPS } from "../../constant/CategoryData";
import styles from "./Maps.module.scss";

const Maps = () => {
  return (
    <div className={styles["contact-wrapper"]}>
      {MAPS.map((x: MapImage) => (
        <div key={x.englishName} className={styles.wrapper}>
          <img src={x.icon} alt={x.englishName}></img>
          <p>{x.nepaliName}</p>
        </div>
      ))}
    </div>
  );
};
export default Maps;
