export const munbnd = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: 11001,
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [86.68118911322347, 27.39068212502405],
              [86.68133821403976, 27.38518598976346],
              [86.68349633450502, 27.38103695754644],
              [86.6822751704813, 27.37296096522654],
              [86.67876478062594, 27.36601695692316],
              [86.67642739176306, 27.36634545009002],
              [86.67457648592914, 27.36449770672329],
              [86.67272830991271, 27.36649930973363],
              [86.66830581085867, 27.36718379749146],
              [86.66568590322994, 27.37003412391661],
              [86.66065236360984, 27.37054542872546],
              [86.65878549252302, 27.37295968270485],
              [86.65529061563664, 27.37105129156275],
              [86.65420208091585, 27.37345618434922],
              [86.65241547730457, 27.37172555699428],
              [86.6520011386613, 27.37544521953876],
              [86.64950074284097, 27.37707934413878],
              [86.64966047686195, 27.37972192514597],
              [86.64375926808724, 27.38455358177733],
              [86.64367424595068, 27.3889961239566],
              [86.63514978014013, 27.39272408268388],
              [86.6271895667393, 27.40158622197603],
              [86.63123672459437, 27.40442647736511],
              [86.6290751376027, 27.40708770394937],
              [86.62953939004161, 27.41276808328141],
              [86.62100518784939, 27.4159784629712],
              [86.61746241745709, 27.4238343914215],
              [86.61790168602762, 27.42899575376762],
              [86.61223014043419, 27.42784069518322],
              [86.60958768706169, 27.43009638696588],
              [86.61501006099947, 27.44350947749405],
              [86.61108358460605, 27.4516754969602],
              [86.61069246114904, 27.4597023400536],
              [86.61241968790314, 27.46757492117608],
              [86.61617328464607, 27.46353296994825],
              [86.62396876531203, 27.46634079331196],
              [86.63018042507683, 27.46185604995108],
              [86.63825221005293, 27.46336868081046],
              [86.64989967539982, 27.45810291254048],
              [86.65429224119588, 27.46021448945979],
              [86.65792630409369, 27.45847483157587],
              [86.65982707595478, 27.46323350489272],
              [86.67104761178271, 27.46871627614961],
              [86.68017692481216, 27.47977314027334],
              [86.67991890155781, 27.48238765371146],
              [86.69172154489239, 27.49090029130719],
              [86.70264181305468, 27.48758696297795],
              [86.70646606156687, 27.4847972151684],
              [86.71023674580341, 27.48524511598094],
              [86.71471471183554, 27.47998134933729],
              [86.7238488354923, 27.4739979772995],
              [86.72310987983198, 27.47067744644584],
              [86.72600223114281, 27.46018449055754],
              [86.72610246631398, 27.45175055303225],
              [86.73186628771518, 27.45683395283548],
              [86.73835704212942, 27.45779398058875],
              [86.7464112200469, 27.4614754195602],
              [86.7525742739828, 27.45930909399996],
              [86.76111888540463, 27.46310340712095],
              [86.76707833282244, 27.46130842092287],
              [86.77119137767707, 27.45752914027448],
              [86.77918509708658, 27.44831134785813],
              [86.77966590224045, 27.44578951697439],
              [86.78768339530633, 27.4381868317461],
              [86.79224296055223, 27.43651049688859],
              [86.80351067455337, 27.43651556916844],
              [86.81451012161043, 27.43320930465978],
              [86.81079159943508, 27.43074939471411],
              [86.80756655436261, 27.42068616787314],
              [86.80377841705263, 27.41538744614167],
              [86.79129620821485, 27.41097630632937],
              [86.78860294941705, 27.40746813309623],
              [86.78183907207847, 27.41085577690306],
              [86.77055972034478, 27.41150552256625],
              [86.76558361960512, 27.40869262225012],
              [86.75955347144271, 27.40009471644804],
              [86.75752701922615, 27.39860425074194],
              [86.75660978417984, 27.40057243548791],
              [86.75175342738014, 27.39448982743236],
              [86.74115530868494, 27.39753918521655],
              [86.73414066557623, 27.39459276124508],
              [86.70975851932238, 27.39561137181644],
              [86.6879609177656, 27.3934591843862],
              [86.68118911322347, 27.39068212502405],
            ],
          ],
        ],
      },
      properties: {
        lg_id: 10305,
        title_en: "thulung dudhkoshi",
        title_ne: "थुलुङ दुधकोशी",
        type_en: "Rural Mun",
        type_ne: "गाउँपालिका",
        website: "thulungdudhkoshimun.gov.np",
        province_id: 1,
        d_id: 103,
        district_title_en: "Solukhumbu",
      },
    },
  ],
};
