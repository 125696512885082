import {
  FaHouseUser,
  FaUserFriends,
  FaMale,
  FaFemale,
  FaTransgender,
} from "react-icons/fa";
import { FcStatistics } from "react-icons/fc";
import { MdPeopleAlt, MdPriceChange } from "react-icons/md";
import ASPECTMAP from "../assests/Aspect Map.png";
import CLIMATEZONEMAP from "../assests/Climate Zone Map.png";
import Bank from "../assests/markers/bank.svg";
import Bridge from "../assests/markers/bridge.svg";
import Fmstation from "../assests/markers/fmstation.svg";
import HealthPost from "../assests/markers/healthpost.svg";
import Hotel from "../assests/markers/hotel.svg";
import Industry from "../assests/markers/industry.svg";
import PoliceStation from "../assests/markers/policestation.svg";
import Settlement from "../assests/markers/settlement.svg";
import Temple from "../assests/markers/temple.svg";
import School from "../assests/markers/school.svg";
import Airport from "../assests/markers/airport.svg";
import Wardoffice from "../assests/markers/wardoffice.svg";
export const CategoryData = [
  {
    catname: "Section I: जनसांख्यिक अवस्थिति",
    icon: MdPeopleAlt,
  },
  {
    catname: "Section II: आर्थिक विकास",
    icon: MdPriceChange,
  },
  {
    catname: "Section III: शिक्षा",
    icon: MdPeopleAlt,
  },
  {
    catname: "Section IV: स्वास्थ्",
    icon: MdPeopleAlt,
  },
  {
    catname: "Section V: कृषी",
    icon: MdPeopleAlt,
  },
  {
    catname: "Section VI: पूर्वाधार विकास",
    icon: MdPeopleAlt,
  },
  {
    catname: "Section VI: विपद व्यवस्थापन",
    icon: MdPeopleAlt,
  },
];

export interface MapImage {
  englishName: string;
  nepaliName: string;
  icon: string;
}
export const MAPS: MapImage[] = [
  {
    englishName: "Aspect Map.png",
    nepaliName: "Aspect Map.png",
    icon: ASPECTMAP,
  },
  {
    englishName: "Climate Zone Map.png",
    nepaliName: "Climate Zone Map.png",
    icon: CLIMATEZONEMAP,
  },
  {
    englishName: "Cultural and Tourism Map.png",
    nepaliName: "Cultural and Tourism Map.png",
    icon: ASPECTMAP,
  },
  {
    englishName: "Aspect Map.png",
    nepaliName: "Aspect Map.png",
    icon: ASPECTMAP,
  },
  {
    englishName: "Aspect Map.png",
    nepaliName: "Aspect Map.png",
    icon: ASPECTMAP,
  },
  {
    englishName: "Aspect Map.png",
    nepaliName: "Aspect Map.png",
    icon: ASPECTMAP,
  },
  {
    englishName: "Aspect Map.png",
    nepaliName: "Aspect Map.png",
    icon: ASPECTMAP,
  },
  {
    englishName: "Aspect Map.png",
    nepaliName: "Aspect Map.png",
    icon: ASPECTMAP,
  },
];


export const MARKER_ICON_MAPPING = [
  {
    name: "bank.svg",
    Icon: Bank,
    name_np:" बैङ्क",
  },
  {
    name: "airpoty.svg",
    Icon: Airport,
    name_np:"एयरपोर्ट",
  },
  {
    name: "fmstation.svg",
    Icon: Fmstation,
    name_np:"एफ.एम",
  },
  {
    name: "forest.svg",
    Icon: HealthPost,
    name_np:"स्वास्थ्य चौकी",
  },
  {
    name: "policestation.svg",
    Icon: PoliceStation,
    name_np:" प्रहरी चौकी",
  },
  {
    name: "school.svg",
    Icon: School,
    name_np:"बिद्यालय",
  },
  {
    name: "settlement.svg",
    Icon: Settlement,
    name_np:"बस्ति",
  },
  {
    name: "wardoffice.svg",
    Icon: Wardoffice,
    name_np:"कार्यालय",
  },
  
];
