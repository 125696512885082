import { useCallback, useState } from "react";
import { apiService } from "../Services/HttpServices";

export type User = {
  id: string;
  name: string;
  avatar: string;
  email: string;
};

export const useGetUser = <T>(url: string) => {
  const [users, setUsers] = useState<T>();
  const [error, setError] = useState<string>();
  const fetchUsers = useCallback(async () => {
    try {
      const { data } = await apiService.get<T>(url);
      setUsers(data);
    } catch (error: any) {
      setError(error);
    }
  }, []);

  return { fetchUsers, users, error };
};
