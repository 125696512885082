import { useCallback, useState } from "react";
import { apiService } from "../Services/HttpServices";

const URL = "https://api.venture4tech.com";
export const useGetSubCatTable = (wardId: number | undefined) => {
  const [subCatTable, setSubCatTable] = useState<any>();
  const [error, setError] = useState<string>();
  const [isloading, setLoading] = useState<boolean>(true);
  const fetchCatTable = useCallback(
    async (url: string) => {
      try {
        setSubCatTable(null);
        setLoading(true);
        const { data } = await apiService.get<any>(
          URL + url + `?ward=${wardId || ""}`
        );
        setSubCatTable(data);
        setLoading(false);
      } catch (error: any) {
        setError(error);
      }
    },
    [URL, wardId]
  );

  return { fetchCatTable, subCatTable, error, isloading };
};
