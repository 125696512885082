import Card from "../shared/Button/Card/Card";
import styles from "./SubContainer.module.scss";

type ContainerProps = {
  heading: string;
};
const SubContainer = ({
  heading,

  children,
}: React.PropsWithChildren<ContainerProps>) => {
  return (
    <>
      <h3 className={styles["sub-heading"]}>{heading}</h3>
      <div className={styles.container}>{children}</div>
    </>
  );
};

export default SubContainer;
