import Layout from "../Layout/Layout";

const RouteWrapper = ({ component: Component, ...rest }: any) => {
  return (
    <Layout>
      <Component />
    </Layout>
  );
};
export default RouteWrapper;
