import { type } from "os";
import { combineReducers } from "redux";
import testReducer from "./testReducer";

const reducers = combineReducers({
  user: testReducer,
});

export default reducers;

export type State = ReturnType<typeof reducers>;
