import cx from "classnames";
import { icon } from "leaflet";
import React from "react";
import { IconType } from "react-icons";
import { FaBeer } from "react-icons/fa";
import styles from "./Card.module.scss";

type CProps = {
  className?: string;
  title: string;
  Icon?: string;
  alt?: string;
  color?: string;
  value?: any;
  onClick?: () => void;
};
const Card = ({
  className,
  title,
  Icon,
  alt,
  onClick,
  children,
  color,
  value,
}: React.PropsWithChildren<CProps>) => {
  return (
    <div className={cx(styles["card-wrapper"], className)} onClick={onClick}>
      {Icon && (
        <img
          src={Icon}
          alt={title}
          style={{ width: "50px", height: "50px", marginRight: "15px" }}
        />
      )}

      <div className={styles["card-detail"]}>
        {value && <div className={styles.value}>{value}</div>}
        <p className={styles["count-title"]}>{title}</p>
      </div>
    </div>
  );
};

export default Card;
