import React, { Children } from "react";
import styles from "./ContentWrapper.module.scss";
import cx from "classnames";
type CProps = {
  title: string;
  wrapperClassName?: string;
};
const ContentWrapper = ({
  title,
  children,
  wrapperClassName,
}: React.PropsWithChildren<CProps>) => {
  return (
    <div className={cx(styles["container-wrapper"])}>
      <h3>{title}</h3>
      <div className={cx(styles.content, wrapperClassName)}>{children}</div>
    </div>
  );
};

export default ContentWrapper;
