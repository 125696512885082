import { Action } from "../Actions";

const initialState = 0;

const testReducer = (state: number = initialState, action: Action) => {
  switch (action.type) {
    case "deposit":
      return state + action.payload;
    case "bankrupt":
      return 0;
    default:
      return state;
  }
};
export default testReducer;
