import { useCallback, useState } from "react";
import { apiService } from "../Services/HttpServices";

const URL = "https://api.venture4tech.com/api/Category";
export const useGetCategory = (url: string) => {
  const [categories, setCategories] = useState<any>();
  const [error, setError] = useState<string>();
  const [isloading, setLoading] = useState<boolean>(true);
  const fetchCategory = useCallback(async () => {
    try {
      const { data } = await apiService.get<any>(URL);
      setCategories(data);
      setLoading(false);
    } catch (error: any) {
      setError(error);
    }
  }, [URL]);

  return { fetchCategory, categories, error, isloading };
};
