import React from 'react';
import "./Footer.scss";

const date = new Date().getFullYear();

const Footer = () => {
        return(
        <div className="footer">
        <h3> Copyright &copy; {date} Venture Four Technology. All Rights Reserved. </h3>
        </div>
        )
}

export default Footer;