import React from "react";
import styles from "./Header.module.scss";
import headerImage from "../../assests/head.png";
import { ReactComponent as Logo } from "../../assests/logo.svg";
import Button from "../shared/Button";
import { NavLink } from "react-router-dom";
import NavBar from "../NavBar";
const Header = () => {
  return (
    <div className={styles["header-wrapper"]}>
      <div className={styles.left}>
        <img src={headerImage}></img>
        <div className={styles["left-label"]}>
          <p>नेपाल सरकार </p>
          <p className={styles.red}> थुलुङ्ग दुधकोशी गाउँपालिका</p>
          <p className={styles.red}>प्रदेश नं १, सोलुखुम्बु, नेपाल </p>
        </div>
      </div>

      <NavBar />

      <div className={styles.right}>
        <NavLink to={"/login"}>
          <Button type="button" title="Login" />
        </NavLink>
      </div>
    </div>
  );
};

export default Header;
