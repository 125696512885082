import React from "react";
import WardRow from "../WardRow";
import styles from "./Page2.module.scss";
import Page2Container from "../Page2Container";
import Page2ContainerTitleRow from "../Page2ContainerTitleRow";
import OptionsList from "../OptionsList";
import MapBox from "../MapBox";

type Page2Props = {
  wardId: number | undefined;
  setWard: React.Dispatch<React.SetStateAction<number | undefined>>;
};
const Page2 = ({ wardId, setWard }: React.PropsWithChildren<Page2Props>) => {
  return (
    <div className={styles.main}>
      <div className={styles.mainCard}>
        {/* <h2
          style={{
            margin: "15px 0px",
            width: "600px",
            fontWeight: "600",
            fontSize: "25px",
          }}
        >
          थुलुङ्ग दुधकोशी गाउँपालिका प्रदेश नं १, सोलुखुम्बु, नेपाल
        </h2> */}
        
        <MapBox wardId={wardId} setWard={setWard} />
      </div>
    </div>
  );
};

export default Page2;
