import React, { useState } from "react";
import { CategoryData } from "../../constant/CategoryData";
import Card from "../shared/Button/Card/Card";
import SubContainer from "../SubContainer";
import styles from "./Column.module.scss";
import cx from "classnames";
import { useGetCategory } from "../../Hooks/useGetCategory";
import { idText } from "typescript";
import { loadavg } from "os";
import Modal from "react-bootstrap/esm/Modal";
import { Button, Tab } from "react-bootstrap";
import { useGetSubCatTable } from "../../Hooks/useGetSubCatTable";
import Loader from "../shared/Loader";
import { useGetHeaderConversion } from "../../Hooks/useGetHeaderConversion";
import defaultImg from "../../assests/logo.svg";
import Tabs from "react-bootstrap/Tabs";
import ReactApexChart from "react-apexcharts";

type ColumnProps = {
  wardId: number | undefined;
  setWard: React.Dispatch<React.SetStateAction<number | undefined>>;
};
const getImage = (icon: string): string => {
  try {
    let test = require(`../../assests/icons/${icon}`);
    return test;
  } catch (error) {
    return defaultImg;
  }
};

const Column = ({ wardId, setWard }: React.PropsWithChildren<ColumnProps>) => {
  const [chartData, setChartData] = React.useState<any>({
    series: [],
    options: {
      chart: {
        width: 400,
        type: "pie",
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "top",
            },
          },
        },
      ],
    },
  });
  const { headerConversion, isloading: isConversionLoad } =
    useGetHeaderConversion();
  const [show, setShow] = useState(false);
  const [subCat, setSubCat] = useState<any>();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [subcategory, setSubCategory] = React.useState<any>();
  const handleClick = (item: any) => {
    setSubCategory(item);
  };

  const {
    fetchCatTable,
    subCatTable,
    isloading: isLoad,
  } = useGetSubCatTable(wardId);

  const { fetchCategory, categories, isloading } = useGetCategory("");

  React.useEffect(() => {
    fetchCategory();
  }, []);

  React.useEffect(() => {
    if (!isloading) {
      categories &&
        categories.data &&
        categories.data.length > 0 &&
        setSubCategory(categories.data[0]);
    }
  }, [isloading, categories]);
  const handleCardClick = (item: any) => {
    setSubCat(item);
    fetchCatTable(item.api_url);
    handleShow();
  };

  React.useEffect(() => {
    if (subCatTable?.label.length > 0) {
      const seriesData = subCatTable?.series.map((y: string) => parseInt(y));
      if (seriesData.length < 6) {
        setChartData({
          series: [...seriesData],
          options: {
            chart: {
              width: 400,
              type: "donut",
            },
            dataLabels: {
              enabled: true,
              formatter: function (val: any) {
                return val.toFixed(2) + "%";
              },
              offsetY: -20,
              style: {
                fontSize: "12px",
                colors: ["#304758"],
              },
            },
            labels: [...subCatTable.label],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "top",
                  },
                },
              },
            ],
          },
        });
      } else {
        setChartData({
          series: [
            {
              name: "Total",
              data: [...seriesData],
            },
          ],
          options: {
            chart: {
              height: 700,
              type: "bar",
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                dataLabels: {
                  position: "top", // top, center, bottom
                },
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function (val: any) {
                return val;
              },
              offsetY: -20,
              style: {
                fontSize: "12px",
                colors: ["#304758"],
              },
            },

            xaxis: {
              categories: [...subCatTable.label],
              position: "bottom",
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              crosshairs: {
                fill: {
                  type: "gradient",
                  gradient: {
                    colorFrom: "#D8E3F0",
                    colorTo: "#BED1E6",
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                  },
                },
              },
              tooltip: {
                enabled: true,
              },
            },
            yaxis: {
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
                formatter: function (val: any) {
                  return val;
                },
              },
            },
            // title: {
            //   text: "Monthly Inflation in Argentina, 2002",
            //   floating: true,
            //   offsetY: 330,
            //   align: "center",
            //   style: {
            //     color: "#444",
            //   },
            // },
          },
        });
      }
    }
  }, [subCatTable]);
  console.log(chartData);
  return (
    <>
      <div className={styles.column}>
        <div className={styles["column-wrapper"]}>
          <div className={styles.leftContent}>
            <ul>
              {!isloading &&
                subcategory &&
                categories &&
                categories.data?.map((element: any) => {
                  return (
                    <li
                      key={element.cat_id}
                      className={
                        subcategory.category === element.category
                          ? styles.active
                          : ""
                      }
                      onClick={() => handleClick(element)}
                    >
                      {/* <element.icon
                      className={cx(
                        styles.icon,
                        currentData === element ? styles.activeicon : ""
                      )}
                    />{" "} */}

                      <img
                        key={element.icon}
                        src={getImage(element.icon)}
                        alt=""
                        style={{
                          height: "40px",
                          width: "40px",
                          marginRight: "15px",
                        }}
                      ></img>
                      {element.category}
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className={styles.rightContent}>
            <SubContainer heading={subcategory?.category}>
              {subcategory &&
                subcategory.subCategories.map((x: any) => (
                  <Card
                    key={x.subcategory}
                    title={x.subcategory}
                    className={styles.card}
                    Icon={getImage(x.icon)}
                    onClick={() => handleCardClick(x)}
                  />
                ))}
            </SubContainer>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className={styles.modalheader}>
          <Modal.Title>{subCat && subCat.subcategory}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.body}>
          <Tabs
            defaultActiveKey="summary-table"
            id="uncontrolled-tab-example"
            //  className="mb-12"
          >
            <Tab eventKey="summary-table" title="समग्र विवरण">
              {isLoad && <Loader />}
              {!isLoad && (
                <table>
                  <tr>
                    {subCatTable &&
                      headerConversion &&
                      headerConversion.data &&
                      subCatTable.headers
                        .filter(
                          (y: any) =>
                            !y.includes("_id") &&
                            !y.includes("ward") &&
                            !y.includes("mun_pop_caste")
                        )
                        .map((x: any) => (
                          <th key={x}>
                            {
                              headerConversion.data.find(
                                (y: any) => y.header_en_name === x
                              ).header_np_name
                            }
                          </th>
                        ))}
                  </tr>
                  {subCatTable &&
                    subCatTable.data.map((x: any, index: number) => (
                      <tr className={styles.trpp} key={x[0]}>
                        {subCatTable &&
                          subCatTable.headers
                            .filter(
                              (y: any) =>
                                !y.includes("_id") &&
                                !y.includes("ward") &&
                                !y.includes("mun_pop_caste")
                            )
                            .map((y: any) => <td key={y}>{x[y]}</td>)}
                      </tr>
                    ))}
                </table>
              )}
            </Tab>
            {!wardId &&(
              <Tab eventKey="ward-table" title="वडागत विवरण">
              {isLoad && <Loader />}
              {!isLoad && (
                <table>
                  <tr>
                    {subCatTable &&
                      headerConversion &&
                      headerConversion.data &&
                      Object.keys(subCatTable.summaryData[0]).map((x: any) => (
                        <th key={x}>
                          {
                            headerConversion.data.find(
                              (y: any) => y.header_en_name === x
                            ).header_np_name
                          }
                        </th>
                      ))}
                  </tr>

                  {subCatTable &&
                    subCatTable.summaryData.map((x: any, index: number) => (
                      <tr className={styles.trpp} key={x[0]}>
                        {subCatTable &&
                          Object.keys(subCatTable.summaryData[0]).map(
                            (y: any) => <td key={y}>{x[y]}</td>
                          )}
                      </tr>
                    ))}
                </table>
              )}
            </Tab>

            )}
            
            <Tab eventKey="chart" title="ग्राफ हेर्नुहोस">
              <div
                id="chart"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {!isLoad && chartData && subCatTable.label?.length <= 5 ? (
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="donut"
                    height={400}
                    width={900}
                  />
                ) : (
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="bar"
                    height={400}
                    width={900}
                  />
                )}
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Column;
