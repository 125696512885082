import styles from "./LoginMain.module.scss";

import React from 'react';
import Button from "../shared/Button";

const LoginMain = () => {
  return (
         <div className={styles.loginMain}>
          <div className={styles.loginBox}>
              <div className={styles.loginWrapper}>
                  <form>
                  <h2 className="text-center" style={{fontWeight: "600"}}><span style={{color:"#2060b3"}}>L</span><span style={{color:"orangered"}}>GDH</span></h2>
                  <input type="text" id="username" placeholder="username" />
                  <input type="password" id="password" placeholder="password"/>
                  <Button type="button" title="Login" onClick={() => {}} />
              </form>
              </div>
          </div>
         </div>
  )
};

export default LoginMain;
