export const wardbnd = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: 4,
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [86.7444950110371, 27.39687748950745],
              [86.74115530868494, 27.39753918521655],
              [86.73836743063946, 27.39720679147201],
              [86.73731838993834, 27.39678293490291],
              [86.73604214865918, 27.39542215884154],
              [86.73414066557623, 27.39459276124508],
              [86.73162129978932, 27.39496018531333],
              [86.7291821866071, 27.3947027901529],
              [86.72449034863259, 27.39535921288192],
              [86.7210341852844, 27.39491238039512],
              [86.71973596477613, 27.39558667280753],
              [86.71843219538604, 27.39571263927598],
              [86.71499034264788, 27.39526343124709],
              [86.70975851932238, 27.39561137181644],
              [86.69631326406744, 27.39336708765534],
              [86.6879609177656, 27.3934591843862],
              [86.68429785268924, 27.39262056995768],
              [86.68118911322347, 27.39068212502405],
              [86.68111186322506, 27.39116701865045],
              [86.68189167190631, 27.39240529726345],
              [86.68190027705049, 27.39325822161561],
              [86.6811217298305, 27.39434173718483],
              [86.6818328261099, 27.39716606139904],
              [86.68213083780971, 27.40057607700973],
              [86.68192609698961, 27.40134050498272],
              [86.68129847944317, 27.40191444382484],
              [86.68115904247065, 27.4027128699681],
              [86.68258454830942, 27.4053560352807],
              [86.68258759521441, 27.40893684330777],
              [86.68414411017561, 27.4103618687909],
              [86.68519868879326, 27.41192337376648],
              [86.68633269298118, 27.41638668460227],
              [86.69024058350456, 27.41798842372057],
              [86.69479214931197, 27.41636258403539],
              [86.6991106018248, 27.41554616919532],
              [86.70019108721236, 27.41545825798052],
              [86.7022493638103, 27.41623203760042],
              [86.70308150502028, 27.41624511367013],
              [86.70497951035848, 27.41604378948324],
              [86.705952771992, 27.41542536184406],
              [86.7073397712158, 27.41571485955669],
              [86.71284851611463, 27.41447406591313],
              [86.71535106146939, 27.41454685317864],
              [86.72232192871152, 27.41322515395319],
              [86.72691494211715, 27.41372837681262],
              [86.72765980939408, 27.41247306317194],
              [86.7285829534716, 27.41181826267093],
              [86.73435125930652, 27.4090517961253],
              [86.73564511109967, 27.40809302835435],
              [86.73788894805331, 27.40499484266208],
              [86.7415152735193, 27.40269110950217],
              [86.74315221778669, 27.40119364632818],
              [86.74442679670699, 27.39852446693988],
              [86.7444950110371, 27.39687748950745],
            ],
          ],
        ],
      },
      properties: {
        w_id: 4,
        title_en: "4",
        title_ne: "४",
        province_id: 1,
        district_title_en: "Solukhumbu",
        mun_title_en: "thulung dudhkoshi",
        d_id: 103,
      },
    },
    {
      type: "Feature",
      id: 8,
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [86.7238488354923, 27.4739979772995],
              [86.72316608803737, 27.47226599341419],
              [86.72310987983198, 27.47067744644584],
              [86.72404610239658, 27.46918784500111],
              [86.72408056103843, 27.4680146374014],
              [86.72460795711895, 27.46679050461173],
              [86.72484189048599, 27.462727306643],
              [86.72600223114281, 27.46018449055754],
              [86.72612521798229, 27.4572424414292],
              [86.72583609814467, 27.45586780809345],
              [86.72610246631398, 27.45175055303225],
              [86.72190077777222, 27.4483441567056],
              [86.7210786066824, 27.44681271622452],
              [86.72059209253018, 27.44466371026432],
              [86.7174117031223, 27.44147587535327],
              [86.71636503604878, 27.44109470318761],
              [86.71197479546727, 27.44120530511325],
              [86.71071722260345, 27.44062283471706],
              [86.70912516012095, 27.43873091235999],
              [86.70522536389697, 27.43844749445664],
              [86.70408184016821, 27.4377771590805],
              [86.70260072584124, 27.44043652174133],
              [86.70328696190926, 27.44271008220254],
              [86.70292691269047, 27.44423231604243],
              [86.70056030149522, 27.44918895978587],
              [86.69855582226756, 27.45166208059262],
              [86.69994829579224, 27.45312947505365],
              [86.70096977642287, 27.45360770037979],
              [86.7041906112069, 27.45330322066322],
              [86.70506577918113, 27.45471073614535],
              [86.70674659016494, 27.45611545282923],
              [86.70802078632256, 27.45669348662107],
              [86.70722561920705, 27.45764626360993],
              [86.7065427638728, 27.45799228324136],
              [86.70385985761578, 27.45829802172276],
              [86.70303037417546, 27.45966132383199],
              [86.69989972014496, 27.46255620009818],
              [86.69742884943474, 27.46288931464776],
              [86.69473261884058, 27.46384462069232],
              [86.69397840884926, 27.46650543382635],
              [86.69187714118144, 27.46847284786397],
              [86.69195623017194, 27.46963499541808],
              [86.69227724363401, 27.46993809944501],
              [86.6907859164353, 27.47099972929784],
              [86.68926911158958, 27.47111369293128],
              [86.68805827737201, 27.47080638670536],
              [86.686084996594, 27.46975275819335],
              [86.68537816770231, 27.46899531281183],
              [86.68439986042361, 27.46889830449482],
              [86.68146530249524, 27.46908342410197],
              [86.68084156607976, 27.46936857578658],
              [86.67927473182682, 27.47113839119937],
              [86.67764055454388, 27.47191079472605],
              [86.67359152175774, 27.47189684977254],
              [86.6760986384676, 27.47442979385372],
              [86.68017692481216, 27.47977314027334],
              [86.67938181193547, 27.48111606961476],
              [86.67938836178851, 27.48168920675506],
              [86.67991890155781, 27.48238765371146],
              [86.6814753518173, 27.48393229408101],
              [86.68305244041346, 27.48471875125387],
              [86.68530196555982, 27.48725100340489],
              [86.68869128053863, 27.48972246845965],
              [86.69172154489239, 27.49090029130719],
              [86.69219593055035, 27.49043877801648],
              [86.69419129945857, 27.49018596070939],
              [86.69944147080157, 27.48811704840281],
              [86.70264181305468, 27.48758696297795],
              [86.70368756100507, 27.48714245840959],
              [86.70646606156687, 27.4847972151684],
              [86.70723296351913, 27.48467698893215],
              [86.70941591400069, 27.48531109645115],
              [86.71023674580341, 27.48524511598094],
              [86.71072956236073, 27.48494602649596],
              [86.71183045568802, 27.48323576023565],
              [86.71391732685638, 27.4823781846615],
              [86.71471471183554, 27.47998134933729],
              [86.71632571110018, 27.47895121885331],
              [86.71864714143528, 27.47683275980267],
              [86.72245836444272, 27.4752767763933],
              [86.7238488354923, 27.4739979772995],
            ],
          ],
        ],
      },
      properties: {
        w_id: 8,
        title_en: "8",
        title_ne: "८",
        province_id: 1,
        district_title_en: "Solukhumbu",
        mun_title_en: "thulung dudhkoshi",
        d_id: 103,
      },
    },
    {
      type: "Feature",
      id: 7,
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [86.70408184016821, 27.4377771590805],
              [86.70247233246317, 27.43633194611899],
              [86.70141369682942, 27.43456977211777],
              [86.69994641992567, 27.43314512246794],
              [86.69569727251721, 27.43676860264921],
              [86.69515638737302, 27.43759320771332],
              [86.69442065756327, 27.44108894713479],
              [86.69426687333399, 27.44355247529232],
              [86.6939176548499, 27.4441924750299],
              [86.69247763563722, 27.44512349284179],
              [86.68999158142915, 27.44535261682452],
              [86.6876729136973, 27.44689300174474],
              [86.68144460613196, 27.44965196085019],
              [86.67576138380747, 27.45092043713357],
              [86.66893377270218, 27.45401810596982],
              [86.6652470539361, 27.45466585932693],
              [86.65942269027829, 27.4575736880201],
              [86.65792630409369, 27.45847483157587],
              [86.65790303739195, 27.45935469042843],
              [86.65918530124887, 27.46248287240455],
              [86.66168155155685, 27.46467078494062],
              [86.66611226139564, 27.46571489346832],
              [86.66885119069508, 27.46684284108534],
              [86.67214469400666, 27.46982444636859],
              [86.67359152175774, 27.47189684977254],
              [86.67764055454388, 27.47191079472605],
              [86.67927473182682, 27.47113839119937],
              [86.68084156607976, 27.46936857578658],
              [86.68146530249524, 27.46908342410197],
              [86.68439986042361, 27.46889830449482],
              [86.68537816770231, 27.46899531281183],
              [86.686084996594, 27.46975275819335],
              [86.68805827737201, 27.47080638670536],
              [86.68926911158958, 27.47111369293128],
              [86.6907859164353, 27.47099972929784],
              [86.69227724363401, 27.46993809944501],
              [86.69195623017194, 27.46963499541808],
              [86.69187714118144, 27.46847284786397],
              [86.69397840884926, 27.46650543382635],
              [86.69473261884058, 27.46384462069232],
              [86.69742884943474, 27.46288931464776],
              [86.69989972014496, 27.46255620009818],
              [86.70303037417546, 27.45966132383199],
              [86.70385985761578, 27.45829802172276],
              [86.7065427638728, 27.45799228324136],
              [86.70802078632256, 27.45669348662107],
              [86.70674659016494, 27.45611545282923],
              [86.70506577918113, 27.45471073614535],
              [86.7041906112069, 27.45330322066322],
              [86.70096977642287, 27.45360770037979],
              [86.69994829579224, 27.45312947505365],
              [86.69855582226756, 27.45166208059262],
              [86.70056030149522, 27.44918895978587],
              [86.70292691269047, 27.44423231604243],
              [86.70328696190926, 27.44271008220254],
              [86.70260072584124, 27.44043652174133],
              [86.70408184016821, 27.4377771590805],
            ],
          ],
        ],
      },
      properties: {
        w_id: 7,
        title_en: "7",
        title_ne: "७",
        province_id: 1,
        district_title_en: "Solukhumbu",
        mun_title_en: "thulung dudhkoshi",
        d_id: 103,
      },
    },
    {
      type: "Feature",
      id: 2,
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [86.6496525212447, 27.42653633512367],
              [86.65161373016477, 27.42524167156831],
              [86.65440586106506, 27.42538389462572],
              [86.66091064074848, 27.4222748462277],
              [86.66328545356619, 27.4223145085467],
              [86.66499118083463, 27.42171615409993],
              [86.66925786433814, 27.42210313138004],
              [86.67199532369172, 27.42188851024153],
              [86.67778860477479, 27.4200789593217],
              [86.6810551831353, 27.41984272422375],
              [86.68304474648198, 27.4192989324312],
              [86.68514087248212, 27.41816650594634],
              [86.68633269298118, 27.41638668460227],
              [86.68607563267626, 27.41496680670141],
              [86.68519868879326, 27.41192337376648],
              [86.68414411017561, 27.4103618687909],
              [86.68258759521441, 27.40893684330777],
              [86.68258454830942, 27.4053560352807],
              [86.6812876363553, 27.40336975158138],
              [86.68129847944317, 27.40191444382484],
              [86.67639151920291, 27.40372626033754],
              [86.67483121966245, 27.40390084221228],
              [86.66978361713082, 27.40162370684547],
              [86.6678671274272, 27.40118133783066],
              [86.66679863922337, 27.4013164326644],
              [86.66254369584635, 27.40420110233988],
              [86.65611264682816, 27.40779779664655],
              [86.64916748822301, 27.40799934650772],
              [86.64575860509025, 27.40875565724641],
              [86.64449169599445, 27.4093480827378],
              [86.64198409988, 27.40872565743059],
              [86.6350265107151, 27.40582179377391],
              [86.63252885158145, 27.40520149329661],
              [86.63123672459437, 27.40442647736511],
              [86.6290751376027, 27.40708770394937],
              [86.62983575750835, 27.41136771262121],
              [86.62953939004161, 27.41276808328141],
              [86.6292875387945, 27.41322997461741],
              [86.62702082206543, 27.4138196335551],
              [86.62544974667908, 27.41523460565494],
              [86.62646040854376, 27.41609923425089],
              [86.6283352891951, 27.4189088919801],
              [86.62966227392226, 27.42027073154533],
              [86.63040176799541, 27.42181148265092],
              [86.62925831679114, 27.42359774242573],
              [86.62693844676086, 27.42582981672055],
              [86.62683873796583, 27.42738192693222],
              [86.6275549991531, 27.42830441947106],
              [86.62817965843152, 27.4287595775954],
              [86.62961953261089, 27.42870929446781],
              [86.6359670417089, 27.42748726287251],
              [86.64072830050038, 27.42715893785795],
              [86.6451407483681, 27.42598111875443],
              [86.6496525212447, 27.42653633512367],
            ],
          ],
        ],
      },
      properties: {
        w_id: 2,
        title_en: "2",
        title_ne: "२",
        province_id: 1,
        district_title_en: "Solukhumbu",
        mun_title_en: "thulung dudhkoshi",
        d_id: 103,
      },
    },
    {
      type: "Feature",
      id: 9,
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [86.76707833282244, 27.46130842092287],
              [86.77119137767707, 27.45752914027448],
              [86.77234670530358, 27.45564920213901],
              [86.77438990014487, 27.45450858941661],
              [86.77918509708658, 27.44831134785813],
              [86.77966590224045, 27.44578951697439],
              [86.78358301155355, 27.4430722643206],
              [86.7839459901482, 27.44191756025518],
              [86.78510041916381, 27.44112065132003],
              [86.78635529618204, 27.43923627740418],
              [86.78768339530633, 27.4381868317461],
              [86.78910408302045, 27.43827472320264],
              [86.79028893565666, 27.43792446259648],
              [86.79131788538871, 27.43691975747944],
              [86.79224296055223, 27.43651049688859],
              [86.79847002546498, 27.43632780944334],
              [86.80092927749847, 27.43584170819944],
              [86.80351067455337, 27.43651556916844],
              [86.80833994759313, 27.43518652098181],
              [86.8106295524177, 27.43384486766957],
              [86.81212310311089, 27.43404093915196],
              [86.81370396331529, 27.43381742430138],
              [86.81444231595754, 27.43331298191261],
              [86.81312206951887, 27.43248090751374],
              [86.81299934657162, 27.43197757464745],
              [86.81213822445768, 27.43126562300983],
              [86.81079159943508, 27.43074939471411],
              [86.80998017558785, 27.42928388540033],
              [86.80963350790945, 27.42757315624443],
              [86.80862374048375, 27.42586597727206],
              [86.80819987203245, 27.42240865145195],
              [86.80756655436261, 27.42068616787314],
              [86.80609684893626, 27.41890842464044],
              [86.80586777451835, 27.41796044281885],
              [86.80377841705263, 27.41538744614167],
              [86.79852240985393, 27.41284389273598],
              [86.79478909372858, 27.41246386055668],
              [86.79129620821485, 27.41097630632937],
              [86.79058468941432, 27.4103795343483],
              [86.78956455511366, 27.40833826011695],
              [86.78860294941705, 27.40746813309623],
              [86.78711714146063, 27.4075719100937],
              [86.78183907207847, 27.41085577690306],
              [86.78020827745101, 27.41119840548074],
              [86.77120231821128, 27.41174576253103],
              [86.76947987572093, 27.41087199753161],
              [86.76815727608103, 27.4090421366812],
              [86.76742788140703, 27.4085941632427],
              [86.76631427094118, 27.40885405801337],
              [86.76558361960512, 27.40869262225012],
              [86.7637862818904, 27.40634748689528],
              [86.76306843928481, 27.4062808545888],
              [86.7609252636911, 27.40713461707428],
              [86.76034132420789, 27.4078285453005],
              [86.75946319889553, 27.41024131615357],
              [86.75752817669175, 27.41138874262489],
              [86.75444664363476, 27.41417669158687],
              [86.75418407617363, 27.41599933850412],
              [86.75520722026438, 27.41755128146157],
              [86.75389153332864, 27.41966988327621],
              [86.75358940521794, 27.42163689028686],
              [86.7522294333287, 27.42274683081897],
              [86.75186582642046, 27.42409101775709],
              [86.75076223873528, 27.42612200639616],
              [86.7481098507378, 27.42829907910657],
              [86.74607184294267, 27.42939875894514],
              [86.74210642136936, 27.42953361519241],
              [86.74105740101847, 27.43101184080418],
              [86.7385838132006, 27.43267464989821],
              [86.73599588406596, 27.4334888450598],
              [86.73364538946939, 27.43586028132847],
              [86.73144259982031, 27.43657355490656],
              [86.72737274891102, 27.43696726101695],
              [86.72453864997604, 27.43844183143461],
              [86.71997404576568, 27.43903519936305],
              [86.717623268135, 27.44008868944225],
              [86.71636503604878, 27.44109470318761],
              [86.7174117031223, 27.44147587535327],
              [86.71908645356551, 27.44288046702207],
              [86.72059209253018, 27.44466371026432],
              [86.7210786066824, 27.44681271622452],
              [86.72190077777222, 27.4483441567056],
              [86.7276175585958, 27.45271918110575],
              [86.7284748661382, 27.45353993585413],
              [86.7293909548865, 27.45542347175039],
              [86.73099209616878, 27.45653671385046],
              [86.73377615351026, 27.4572460119935],
              [86.73835704212942, 27.45779398058875],
              [86.74138723839845, 27.459282026279],
              [86.74405669019045, 27.45980815097797],
              [86.7464112200469, 27.4614754195602],
              [86.74756437957149, 27.46160386570002],
              [86.74947001721068, 27.46103644921468],
              [86.75223000691786, 27.45930852600224],
              [86.75386784621753, 27.45969951966614],
              [86.75722593516372, 27.46127581370379],
              [86.75892682999019, 27.46162399988755],
              [86.76111888540463, 27.46310340712095],
              [86.76707833282244, 27.46130842092287],
            ],
          ],
        ],
      },
      properties: {
        w_id: 9,
        title_en: "9",
        title_ne: "९",
        province_id: 1,
        district_title_en: "Solukhumbu",
        mun_title_en: "thulung dudhkoshi",
        d_id: 103,
      },
    },
    {
      type: "Feature",
      id: 6,
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [86.65792630409369, 27.45847483157587],
              [86.6652470539361, 27.45466585932693],
              [86.66893377270218, 27.45401810596982],
              [86.67576138380747, 27.45092043713357],
              [86.68144460613196, 27.44965196085019],
              [86.6876729136973, 27.44689300174474],
              [86.68999158142915, 27.44535261682452],
              [86.69247763563722, 27.44512349284179],
              [86.6939176548499, 27.4441924750299],
              [86.69426687333399, 27.44355247529232],
              [86.69442065756327, 27.44108894713479],
              [86.69515638737302, 27.43759320771332],
              [86.69569727251721, 27.43676860264921],
              [86.69994641992567, 27.43314512246794],
              [86.69778021923146, 27.43245227195556],
              [86.69719012028438, 27.43193424450431],
              [86.69360140045056, 27.42626980986121],
              [86.69151833660283, 27.4245007433329],
              [86.69081634401691, 27.42267833022177],
              [86.69072207311254, 27.42134688421088],
              [86.69123997851436, 27.41969191458811],
              [86.6909937857892, 27.41854963657297],
              [86.68636356536544, 27.41630781775702],
              [86.68514087248212, 27.41816650594634],
              [86.68304474648198, 27.4192989324312],
              [86.6810551831353, 27.41984272422375],
              [86.67778860477479, 27.4200789593217],
              [86.67199532369172, 27.42188851024153],
              [86.66925786433814, 27.42210313138004],
              [86.66499118083463, 27.42171615409993],
              [86.66328545356619, 27.4223145085467],
              [86.66091064074848, 27.4222748462277],
              [86.65440586106506, 27.42538389462572],
              [86.65161373016477, 27.42524167156831],
              [86.6496525212447, 27.42653633512367],
              [86.64937761488828, 27.42961774464999],
              [86.64809530445132, 27.43149630531984],
              [86.64812227580505, 27.43265389037569],
              [86.65179827241916, 27.44510672436212],
              [86.65234359358661, 27.450060732039],
              [86.65222641383532, 27.45325088419274],
              [86.65172913190538, 27.45483354697679],
              [86.65159885895851, 27.4566518214098],
              [86.64989967539982, 27.45810291254048],
              [86.65032123229885, 27.45865674561147],
              [86.65193163685093, 27.45912329539157],
              [86.65343860595281, 27.46009504720485],
              [86.65429224119588, 27.46021448945979],
              [86.65504958489404, 27.4599817104424],
              [86.65580818963971, 27.45923672752318],
              [86.65792630409369, 27.45847483157587],
            ],
          ],
        ],
      },
      properties: {
        w_id: 6,
        title_en: "6",
        title_ne: "६",
        province_id: 1,
        district_title_en: "Solukhumbu",
        mun_title_en: "thulung dudhkoshi",
        d_id: 103,
      },
    },
    {
      type: "Feature",
      id: 1,
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [86.64989967539982, 27.45810291254048],
              [86.65159885895851, 27.4566518214098],
              [86.65172913190538, 27.45483354697679],
              [86.65222641383532, 27.45325088419274],
              [86.65234359358661, 27.450060732039],
              [86.65179827241916, 27.44510672436212],
              [86.64812227580505, 27.43265389037569],
              [86.64809530445132, 27.43149630531984],
              [86.64937761488828, 27.42961774464999],
              [86.6496525212447, 27.42653633512367],
              [86.6451407483681, 27.42598111875443],
              [86.64072830050038, 27.42715893785795],
              [86.6375427770396, 27.42727249722552],
              [86.62817965843152, 27.4287595775954],
              [86.62683873796583, 27.42738192693222],
              [86.62675051857968, 27.42653331314601],
              [86.62711955396932, 27.4255527811899],
              [86.63007435684375, 27.42256653519223],
              [86.63040176799541, 27.42181148265092],
              [86.62966227392226, 27.42027073154533],
              [86.62568533943704, 27.41530972842797],
              [86.62231315389835, 27.41539316207771],
              [86.62100518784939, 27.4159784629712],
              [86.61833231813806, 27.42032809304284],
              [86.61814110996723, 27.42243277803879],
              [86.61746241745709, 27.4238343914215],
              [86.61791892374691, 27.42656578210358],
              [86.61831141903959, 27.42730916027956],
              [86.61832127266203, 27.42822751664356],
              [86.61790168602762, 27.42899575376762],
              [86.61739736560891, 27.42951338665062],
              [86.61653872924482, 27.42945681375742],
              [86.6150770626484, 27.42865635981044],
              [86.61420511696538, 27.42853210628265],
              [86.613273191843, 27.42784808770935],
              [86.61223014043419, 27.42784069518322],
              [86.61144454786279, 27.42822433545455],
              [86.60958768706169, 27.43009638696588],
              [86.61070785492467, 27.43289290780886],
              [86.61231286288421, 27.43437074224059],
              [86.6125650341119, 27.4364855591492],
              [86.611714805933, 27.43771968452017],
              [86.61489509457489, 27.44265855173721],
              [86.61501006099947, 27.44350947749405],
              [86.61471346035684, 27.44416072775917],
              [86.61345160597641, 27.44503718983982],
              [86.612488510618, 27.44662522008448],
              [86.61187501921953, 27.4499357907807],
              [86.61108358460605, 27.4516754969602],
              [86.61069246114904, 27.4597023400536],
              [86.61125521847346, 27.46170298492007],
              [86.61103389203792, 27.46368343070986],
              [86.61241968790314, 27.46757492117608],
              [86.61283495294172, 27.46727370486591],
              [86.6130338275757, 27.46609418874559],
              [86.61459231824203, 27.46573980938075],
              [86.61617328464607, 27.46353296994825],
              [86.62137230001649, 27.46602465189174],
              [86.62396876531203, 27.46634079331196],
              [86.62824987638786, 27.46420649492167],
              [86.6288420585507, 27.46373429735324],
              [86.62933164725439, 27.4624742923204],
              [86.63018042507683, 27.46185604995108],
              [86.63346515486641, 27.46312830650015],
              [86.63522486671849, 27.46286892197796],
              [86.63825221005293, 27.46336868081046],
              [86.64013831160092, 27.46170392401681],
              [86.64428128484444, 27.46099249619843],
              [86.64989967539982, 27.45810291254048],
            ],
          ],
        ],
      },
      properties: {
        w_id: 1,
        title_en: "1",
        title_ne: "१",
        province_id: 1,
        district_title_en: "Solukhumbu",
        mun_title_en: "thulung dudhkoshi",
        d_id: 103,
      },
    },
    {
      type: "Feature",
      id: 3,
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [86.68129847944317, 27.40191444382484],
              [86.68192609698961, 27.40134050498272],
              [86.68213083780971, 27.40057607700973],
              [86.6818328261099, 27.39716606139904],
              [86.6811217298305, 27.39434173718483],
              [86.68190027705049, 27.39325822161561],
              [86.68189167190631, 27.39240529726345],
              [86.68111186322506, 27.39116701865045],
              [86.68091453454528, 27.39022797307997],
              [86.68106603236971, 27.38654817654015],
              [86.6818719791516, 27.38414027932165],
              [86.68339082454183, 27.38214235766171],
              [86.68349633450502, 27.38103695754644],
              [86.68236084676698, 27.37870133224141],
              [86.6822751704813, 27.37296096522654],
              [86.68119524852878, 27.37013132190617],
              [86.67876478062594, 27.36601695692316],
              [86.67642739176306, 27.36634545009002],
              [86.67583554276236, 27.36584092168262],
              [86.67535138004042, 27.36472744956419],
              [86.67483157875122, 27.36443965374199],
              [86.67272830991271, 27.36649930973363],
              [86.66830581085867, 27.36718379749146],
              [86.66740685035924, 27.36785859694548],
              [86.66568590322994, 27.37003412391661],
              [86.66392018733355, 27.37046080568432],
              [86.66239620425337, 27.3702450485731],
              [86.66065236360984, 27.37054542872546],
              [86.65878549252302, 27.37295968270485],
              [86.65666684075113, 27.37255736899359],
              [86.65529061563664, 27.37105129156275],
              [86.65471561740623, 27.37157779754667],
              [86.65464865590984, 27.37309391192446],
              [86.65420208091585, 27.37345618434922],
              [86.65241547730457, 27.37172555699428],
              [86.65245172496806, 27.37451449752342],
              [86.6520011386613, 27.37544521953876],
              [86.64950074284097, 27.37707934413878],
              [86.64931018922381, 27.37778734008042],
              [86.64966047686195, 27.37972192514597],
              [86.64900235529335, 27.38062502077896],
              [86.64423309519017, 27.38390496047088],
              [86.64375926808724, 27.38455358177733],
              [86.64338929534647, 27.38553867551225],
              [86.64433825021415, 27.38767781523153],
              [86.64367424595068, 27.3889961239566],
              [86.64024904707715, 27.39000266274822],
              [86.63514978014013, 27.39272408268388],
              [86.631197407144, 27.39627194980121],
              [86.6271895667393, 27.40158622197603],
              [86.6289828508341, 27.40359238685606],
              [86.63051164127883, 27.4039935659144],
              [86.63252885158145, 27.40520149329661],
              [86.6350265107151, 27.40582179377391],
              [86.64198409988, 27.40872565743059],
              [86.64449169599445, 27.4093480827378],
              [86.64575860509025, 27.40875565724641],
              [86.64916748822301, 27.40799934650772],
              [86.65611264682816, 27.40779779664655],
              [86.66254369584635, 27.40420110233988],
              [86.66679863922337, 27.4013164326644],
              [86.6678671274272, 27.40118133783066],
              [86.66978361713082, 27.40162370684547],
              [86.67483121966245, 27.40390084221228],
              [86.67639151920291, 27.40372626033754],
              [86.68129847944317, 27.40191444382484],
            ],
          ],
        ],
      },
      properties: {
        w_id: 3,
        title_en: "3",
        title_ne: "३",
        province_id: 1,
        district_title_en: "Solukhumbu",
        mun_title_en: "thulung dudhkoshi",
        d_id: 103,
      },
    },
    {
      type: "Feature",
      id: 5,
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [86.71636503604878, 27.44109470318761],
              [86.717623268135, 27.44008868944225],
              [86.71997404576568, 27.43903519936305],
              [86.72453864997604, 27.43844183143461],
              [86.72737274891102, 27.43696726101695],
              [86.73144259982031, 27.43657355490656],
              [86.73364538946939, 27.43586028132847],
              [86.73599588406596, 27.4334888450598],
              [86.7385838132006, 27.43267464989821],
              [86.74105740101847, 27.43101184080418],
              [86.74210642136936, 27.42953361519241],
              [86.74607184294267, 27.42939875894514],
              [86.7481098507378, 27.42829907910657],
              [86.75076223873528, 27.42612200639616],
              [86.75186582642046, 27.42409101775709],
              [86.7522294333287, 27.42274683081897],
              [86.75358940521794, 27.42163689028686],
              [86.75389153332864, 27.41966988327621],
              [86.75520722026438, 27.41755128146157],
              [86.75418407617363, 27.41599933850412],
              [86.75444664363476, 27.41417669158687],
              [86.75752817669175, 27.41138874262489],
              [86.75946319889553, 27.41024131615357],
              [86.76034132420789, 27.4078285453005],
              [86.7609252636911, 27.40713461707428],
              [86.76306843928481, 27.4062808545888],
              [86.7637862818904, 27.40634748689528],
              [86.7621674807201, 27.40407040964572],
              [86.76156097030207, 27.40246729161455],
              [86.76013023428176, 27.40180601237155],
              [86.75955347144271, 27.40009471644804],
              [86.75752701922615, 27.39860425074194],
              [86.75660978417984, 27.40057243548791],
              [86.75346476317813, 27.39746221934654],
              [86.75175342738014, 27.39448982743236],
              [86.75134404472544, 27.39441007784107],
              [86.74887147208752, 27.39537588604781],
              [86.74755657744727, 27.39553816192847],
              [86.74662530582775, 27.3964075061221],
              [86.7444950110371, 27.39687748950745],
              [86.74442679670699, 27.39852446693988],
              [86.74315221778669, 27.40119364632818],
              [86.7415152735193, 27.40269110950217],
              [86.73788894805331, 27.40499484266208],
              [86.73564511109967, 27.40809302835435],
              [86.73435125930652, 27.4090517961253],
              [86.7285829534716, 27.41181826267093],
              [86.72765980939408, 27.41247306317194],
              [86.72691494211715, 27.41372837681262],
              [86.72232192871152, 27.41322515395319],
              [86.71535106146939, 27.41454685317864],
              [86.71284851611463, 27.41447406591313],
              [86.7073397712158, 27.41571485955669],
              [86.705952771992, 27.41542536184406],
              [86.70497951035848, 27.41604378948324],
              [86.70308150502028, 27.41624511367013],
              [86.7022493638103, 27.41623203760042],
              [86.70019108721236, 27.41545825798052],
              [86.6991106018248, 27.41554616919532],
              [86.69479214931197, 27.41636258403539],
              [86.69024058350456, 27.41798842372057],
              [86.69116756515868, 27.41899897519206],
              [86.69123997851436, 27.41969191458811],
              [86.69072207311254, 27.42134688421088],
              [86.69081634401691, 27.42267833022177],
              [86.69151833660283, 27.4245007433329],
              [86.69360140045056, 27.42626980986121],
              [86.69719012028438, 27.43193424450431],
              [86.69778021923146, 27.43245227195556],
              [86.69994641992567, 27.43314512246794],
              [86.70141369682942, 27.43456977211777],
              [86.70247233246317, 27.43633194611899],
              [86.70522536389697, 27.43844749445664],
              [86.70912516012095, 27.43873091235999],
              [86.71071722260345, 27.44062283471706],
              [86.71197479546727, 27.44120530511325],
              [86.71636503604878, 27.44109470318761],
            ],
          ],
        ],
      },
      properties: {
        w_id: 5,
        title_en: "5",
        title_ne: "५",
        province_id: 1,
        district_title_en: "Solukhumbu",
        mun_title_en: "thulung dudhkoshi",
        d_id: 103,
      },
    },
  ],
  crs: null,
  properties: {
    lg_id: "10305",
    title_en: "thulung dudhkoshi",
    title_ne: "थुलुङ दुधकोशी",
    district_title_en: "Solukhumbu",
    d_id: 103,
    province_id: 1,
  },
};
