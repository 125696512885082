import React, { useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Page2 from "../Page2";
import styles from "./Contact.module.scss";

const Contact = () => {
  return (
    <div className={styles["contact-wrapper"]}>
      <Row />
      <h3>सम्पर्क ठेगाना</h3>
      <p> थुलुङ दुधकाेशी गाउँपालिकाको कार्यालय </p>
      <p>ठेगाना: थुलुङ दुधकाेशी - ०६, मुक्ली, साेलुखुम्बु</p>
      <p>
        ईमेल: thulungdudhkoshirm@gmail.com <br></br>{" "}
        info@thulungdudhkoshimun.gov.np
      </p>
      <p>मोवाईल नं.: ९८४३३१३०५० (सूचना अधिकारी)</p>
      <p> मोवाईल नं.: ९८५२८२९००० (नि. प्रमुख प्रशासकीय अधिकृत)</p>
    </div>
  );
};
export default Contact;
