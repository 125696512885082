import React, { useContext, useState } from "react";
import { Button, Container } from "react-bootstrap";

import { useGetUser } from "../../Hooks/useGetUser";
import Page2 from "../Page2";
import Row from "../Row";
import styles from "./Home.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { stat } from "fs";
import { State } from "../../State/Reducer";
import ContentWrapper from "../Container";
import OptionsList from "../OptionsList";
import Column from "../Column";
import SubContainer from "../SubContainer";
const Home = () => {
  const dispatch = useDispatch();
  const [ward, setward] = useState<number | undefined>();
  const { users, fetchUsers, error } = useGetUser("/todos/1");
  const test = useSelector((state: State) => state.user);

  React.useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className={styles["home-wrapper"]}>
      {/* <Row /> */}
      <ContentWrapper
        title="LGDH - Local Government Data Hub"
        wrapperClassName={styles.content}
      >
        <Page2 wardId={ward} setWard={setward} />
        <Row wardId={ward} setWard={setward} />
      </ContentWrapper>
      <Column wardId={ward} setWard={setward} />
    </div>
  );
};
export default Home;
