import React, { useCallback, useState } from "react";
import { apiService } from "../Services/HttpServices";

const URL =
  "https://api.venture4tech.com/api/Introduction/tableheaderconversion";
export const useGetHeaderConversion = () => {
  const [headerConversion, setheaderConversion] = useState<any>();
  const [error, setError] = useState<string>();
  const [isloading, setLoading] = useState<boolean>(true);
  const fetchHeaderConversion = useCallback(async () => {
    try {
      setheaderConversion(null);
      setLoading(true);
      const { data } = await apiService.get<any>(URL);
      setheaderConversion(data);
      setLoading(false);
    } catch (error: any) {
      setError(error);
    }
  }, [URL]);

  React.useEffect(() => {
    fetchHeaderConversion();
  }, []);

  return { headerConversion, error, isloading };
};
