import { type } from "os";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./NavBar.module.scss";

type Menuitem = {
  title: string;
  url: string;
};
const menuItems: Menuitem[] = [
  {
    title: "गृहपृष्ठ",
    url: "/",
  },
  {
    title: "स्रोत नक्सा",
    url: "/maps",
  },
  {
    title: "सम्पर्क",
    url: "/contact",
  },
  // {
  //   title: "सपोर्ट",
  //   url: "/support",
  // },
];
const NavBar = () => {
  return (
    <div className={styles.nav}>
      {menuItems.map((x, index) => (
        <NavLink className={styles.link} key={index} to={x.url}>
          <p>{x.title}</p>
        </NavLink>
      ))}
    </div>
  );
};

export default NavBar;
