import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Layer,
  LineLayer,
  Map,
  MapRef,
  Source,
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from "react-map-gl";
import styles from "./MapBox.module.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import { munbnd } from "../../assests/geojson/munbnd";
import { FillLayer } from "react-map-gl";
import bbox from "@turf/bbox";
import { wardbnd } from "../../assests/geojson/wardbnd";
import { handleInputChange } from "react-select/dist/declarations/src/utils";
import { officelocation } from "../../assests/geojson/office-location";
import Button from "../shared/Button";
import { useGetMarkerLocation } from "../../Hooks/useGetMarkerLocation";
import { ReactComponent as RedIcon } from "../../assests/icons/school.svg";
import Pin from "./pin";
import ControlPanel from "./control-panel";
import OptionsList from "../OptionsList";
/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from "mapbox-gl";
import { MARKER_ICON_MAPPING } from "../../constant/CategoryData";
// @ts-ignore
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
const optionsprovince = [{ label: "प्रदेश नं. १", value: "province1" }];
const optionsdistrict = [{ label: "सोलुखुम्बु", value: "solukhumbu" }];
const optionslg = [
  { label: "थुलुङ्ग दुधकोशी गाँउपालिका", value: "thulung-dudkoshi" },
];

const optionsward = [
  { label: "छान्नुहोस", value: "0" },
  { label: "वडा नं. १", value: "1" },
  { label: "वडा नं. २", value: "2" },
  { label: "वडा नं. ३", value: "3" },
  { label: "वडा नं. ४", value: "4" },
  { label: "वडा नं. ५", value: "5" },
  { label: "वडा नं. ६", value: "6" },
  { label: "वडा नं. ७", value: "7" },
  { label: "वडा नं. ८", value: "8" },
  { label: "वडा नं. ९", value: "9" },
];

type MapColor = {
  id: string;
  color: string;
};

const Colors: MapColor[] = [
  {
    id: "1",
    color: "#50b0cb",
  },
  {
    id: "2",
    color: "#50b0cb",
  },
  {
    id: "3",
    color: "#50b0cb",
  },
  {
    id: "4",
    color: "#50b0cb",
  },
  {
    id: "5",
    color: "#50b0cb",
  },
  {
    id: "6",
    color: "#50b0cb",
  },
  {
    id: "7",
    color: "#50b0cb",
  },
  {
    id: "8",
    color: "#50b0cb",
  },
  {
    id: "9",
    color: "#50b0cb",
  },
];

export const dataLayer: FillLayer = {
  id: "data",
  type: "fill",
  paint: {
    "fill-color": {
      property: "title_en",
      type: "categorical",
      stops: [
        ["1", "#50b0cb"],
        ["2", "#50b0cb"],
        ["3", "#50b0cb"],
        ["4", "#50b0cb"],
        ["5", "#50b0cb"],
        ["6", "#50b0cb"],
        ["7", "#50b0cb"],
        ["8", "#50b0cb"],
        ["9", "#50b0cb"],
      ],
    },

    "fill-opacity": 0.5,
    "fill-outline-color": "white",
  },
  layout: {
    visibility: "visible",
  },
};
type MapBoxProps = {
  wardId: number | undefined;
  setWard: React.Dispatch<React.SetStateAction<number | undefined>>;
};
const MapBox = ({ wardId, setWard }: React.PropsWithChildren<MapBoxProps>) => {
  const [hoverInfo, setHoverInfo] = useState<any>(null);
  const [markerName, setMarkerName] = useState<any>(null);
  const [mapData, setMapData] = useState<any>(wardbnd);
  const myRef = useRef<MapRef>();
  const data = MARKER_ICON_MAPPING.map((x) => x.name);
  const [icons, setIcons] = useState<string[]>(data);

  const [number, setnumber] = useState<number[]>([]);
  const { marker, fetchMarkerLocation, isloading } =
    useGetMarkerLocation(wardId);

  React.useEffect(() => {
    fetchMarkerLocation();
  }, [wardId]);
  const [layer, setLayer] = useState(dataLayer);

  const onHover = useCallback((event) => {
    const {
      features,
      point: { x, y },
    } = event;
    const hoveredFeature = features && features[0];

    const wardId = parseInt(hoveredFeature.properties.title_en);
    const newStops = [] as any;
    for (let index = 1; index <= 9; index++) {
      if (wardId !== index) {
        newStops.push([index.toString(), "#50b0cb"]);
      }
    }

    setLayer({
      ...layer,
      paint: {
        "fill-color": {
          property: "title_en",
          type: "categorical",
          stops: [[hoveredFeature.properties.title_en, "#2687a2"], ...newStops],
        },
        "fill-opacity": 0.5,
        "fill-outline-color": "white",
      },
    });
    // prettier-ignore
    if(hoveredFeature)
    {
      
      setHoverInfo( {...hoverInfo,feature: hoveredFeature, x, y});
    }
  }, []);

  const zoomTocenter = () => {
    const feature = munbnd.features[0];

    if (feature && myRef.current) {
      // calculate the bounding box of the feature
      const [minLng, minLat, maxLng, maxLat] = bbox(feature);

      myRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 40, duration: 1000 }
      );

      setTimeout(() => {
        setnumber([minLng, minLat, maxLng, maxLat]);
      }, 5000);
    }
  };

  React.useEffect(() => {
    zoomTocenter();
  }, []);

  const handleMapClick = (e: any) => {
    const { features } = e;
    const feature = features && features[0];
    const provinceId = feature.properties.title_en.toString();
    const currentColor = Colors.find((x) => x.id === provinceId)?.color;

    setWard(feature.properties.title_en);
    setLayer({
      ...layer,
      paint: {
        "fill-color": {
          property: "title_en",
          type: "categorical",
          stops: [[provinceId, currentColor]],
        },
        "fill-opacity": 0.5,
        "fill-outline-color": "black",
      },
    });
    const wardselecteddata = wardbnd.features.find((x) => x.id === feature.id);
    setMapData(wardselecteddata);

    if (feature && myRef.current) {
      // calculate the bounding box of the feature
      const [minLng, minLat, maxLng, maxLat] = bbox(feature);

      myRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 40, duration: 1000 }
      );
    }
  };

  const palikaZoom = () => {
    setMapData(wardbnd);
    setLayer(dataLayer);
    zoomTocenter();
    setWard(undefined);
  };

  const test = (title: string) => {
    const icon = MARKER_ICON_MAPPING.find((x) => x.name === title)?.Icon || "";
    return icon;
  };

  const markericonClick = (title: any) => {
    setHoverInfo({ ...hoverInfo, name: title });
  };

  const handleChange = (e: any) => {
    const features = wardbnd.features.find((x) => x.id.toString() === e.value);
    if (!features) {
      palikaZoom();
      return;
    }
    const feature = features as any;
    const provinceId = feature.properties.title_en.toString();
    const currentColor = Colors.find((x) => x.id === provinceId)?.color;

    setWard(feature.properties.title_en);
    setLayer({
      ...layer,
      paint: {
        "fill-color": {
          property: "title_en",
          type: "categorical",
          stops: [[provinceId, currentColor]],
        },
        "fill-opacity": 0.5,
        "fill-outline-color": "black",
      },
    });
    const wardselecteddata = wardbnd.features.find((x) => x.id === feature.id);
    setMapData(wardselecteddata);

    if (feature && myRef.current) {
      // calculate the bounding box of the feature
      const [minLng, minLat, maxLng, maxLat] = bbox(feature);

      myRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 40, duration: 1000 }
      );
    }
  };
  console.log(hoverInfo);
  return (
    <div className={styles.MapBox}>
      <div className={styles.listRow}>
        <OptionsList options={optionsprovince} />
        <OptionsList options={optionsdistrict} />
        <OptionsList options={optionslg} />
        <OptionsList
          options={optionsward}
          handleOnChange={handleChange}
          wardId={wardId}
        />
        <Button
          title="पालिका नक्सा"
          type="button"
          className={styles.zoomback}
          onClick={palikaZoom}
        />
      </div>
      <div className={styles["map-layer"]}>
        {
          <Map
            ref={myRef as any}
            initialViewState={{
              latitude: 27.42,
              longitude: 86.71,
              zoom: 11,
            }}
            style={{ height: "650px", width: "100%" }}
            // mapStyle="mapbox://styles/mapbox/light-v9"
            mapboxAccessToken={
              "pk.eyJ1IjoicmVnbWlzYW5pc2giLCJhIjoiY2t6OHVkYjYwMWZrdDJ4cHJrb2s2bTd4eSJ9.Pd6nVXZ9leNw48Y89czfBw"
            }
            interactiveLayerIds={["data"]}
            onMouseMove={onHover}
            attributionControl={false}
            scrollZoom={false}
            doubleClickZoom={true}
            onClick={handleMapClick}
          >
            <GeolocateControl position="top-left" />
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            <ScaleControl />
            <ControlPanel wardId={wardId} setIcons={setIcons} icons={icons} />
            <Source type="geojson" data={mapData}>
              <Layer {...layer} />
            </Source>
            {marker &&
              marker.data
                .filter((y: any) => y.settlement_long !== "")
                .filter((z: any) => icons.some((x: any) => x === z.icon_name))
                .map((officedet: any) => {
                  return (
                    <Marker
                      key={officedet.settlement_id}
                      longitude={Number.parseFloat(
                        officedet.settlement_long !== ""
                          ? officedet.settlement_long
                          : 86.637075
                      )}
                      latitude={Number.parseFloat(
                        officedet.settlement_lat !== ""
                          ? officedet.settlement_lat
                          : 27.438199
                      )}
                    >
                      {
                        <img
                          onClick={(e: any) => {
                            e.stopPropagation();
                            markericonClick(officedet.settlement_name);
                          }}
                          src={test(officedet.icon_name)}
                          alt=""
                        ></img>
                      }
                    </Marker>
                  );
                })}

            {hoverInfo && (
              <div
                className={styles.tooltip}
                style={{
                  left: hoverInfo.x + 10,
                  top: hoverInfo.y,
                  position: "absolute",
                }}
              >
                <div className={styles["map-hover"]}>
                  {!hoverInfo.name &&
                    ` वडा नं.: ${hoverInfo.feature.properties.title_en}`}
                  {hoverInfo.name && hoverInfo.name}
                </div>
              </div>
            )}
          </Map>
        }
      </div>
    </div>
  );
};

export default MapBox;
