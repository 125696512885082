import React from "react";
import { useGetCategory } from "../../Hooks/useGetCategory";
import { useGetHeaderConversion } from "../../Hooks/useGetHeaderConversion";
import { useGetPopulation } from "../../Hooks/useGetPopulation";
import Card from "../shared/Button/Card/Card";
import "./Row.scss";
import RowData from "./RowData";
import defaultImg from "../../assests/logo.svg";

type RowProps = {
  wardId: number | undefined;
  setWard: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const getImage = (icon: string): string => {
  try {
    let test = require(`../../assests/icons/${icon}.svg`);
    return test;
  } catch (error) {
    return defaultImg;
  }
};


const Row = ({ wardId, setWard }: React.PropsWithChildren<RowProps>) => {
  const { fetchPopulation, isloading, population } = useGetPopulation(wardId);
  const { headerConversion, isloading: isConversionLoad } =
    useGetHeaderConversion();
  React.useEffect(() => {
    fetchPopulation();
  }, [wardId]);
  return (
    <div className="Row">
      {population &&
        headerConversion &&
        population.objSumData &&
        population.headers
          .filter(
            (x: any) =>
              x !== "mun_pop_id" &&
              x !== "ward" &&
              x != "popn_density" &&
              x !== "area"
          )
          .map((element: any) => {
           
            return (
              <Card
                key={element}
                title={
                  headerConversion.data?.find(
                    (y: any) => y.header_en_name === element
                  )?.header_np_name || element
                }
                Icon={getImage(element)}
                alt={element}
                // color={element.color}
                //value={  population.objSumData[element]}
                value= {(() => {
                  switch (element) {
                    case "avg_familysize":   return (population.objSumData['mun_pop']/population.objSumData['mun_hh']).toFixed(2);
                    case "gendr_ratio": return (population.objSumData['pop_male']/population.objSumData['pop_female']).toFixed(2);
                    default:      return population.objSumData[element];
                  }
                })()}
              >
                {/* <element.svg /> */}
              </Card>
            );
          })}
    </div>
  );
};

export default Row;
