import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import NavBar from "../NavBar/NavBar";

const Layout = ({ children }: React.PropsWithChildren<any>) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
