import React from "react";
import Select from "react-select";
import styles from "./OptionsList.module.scss";

// type OProps = {
//     className?: string;
//     value: string;
//     id: string;npm,
// }

const OptionsList = ({ options, handleOnChange, wardId }: any) => {
  const onChange = (e: any) => {
    setSelected(e);
    handleOnChange && handleOnChange(e);
  };

  const [selected, setSelected] = React.useState<any>(options[0]);
  React.useEffect(() => {
    if (options.length > 1) {
      if (wardId) {
        const data = options.find((x: any) => x.value === wardId.toString());
        if (data) {
          setSelected(data);
        }
      } else {
        setSelected(options[0]);
      }
    }
  }, [wardId, options]);
  return (
    <div className={styles.customSelect}>
      <Select
        isMulti={false}
        options={options}
        onChange={onChange}
        value={selected}
      />
    </div>
  );
};
export default OptionsList;
